
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import CoursesTable from "@/components/CoursesTable.vue";
import { Action, State } from "vuex-class";
import { State as StateStore } from "../store";
import { CourseAvailability, CourseStatus } from "@/utils/courses";

@Component({
  components: { Card, CoursesTable },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class CoursesListView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getCourses;
  @Action getCoursesByStudent;

  tabKeys = ["coursesInProgress", "coursesList", "endedCoursesList"];

  async mounted() {
    await this.onChangeTab();
  }

  async onChangeTab() {
    const tabKey = this.$route.query.tab;

    switch (this.tabKeys.indexOf(tabKey as string)) {
      case 0: {
        await this.getCoursesByStudent({
          userGuid: this.user.guid,
          availability: [CourseAvailability.Public, CourseAvailability.Private],
          status: [CourseStatus.Published],
        });
        break;
      }
      case 1: {
        await this.getCourses({
          userGuid: this.user.guid,
          availability: CourseAvailability.Public,
          status: [CourseStatus.Published],
          hideStartedNotSubscribed: true
        });
        break;
      }
      case 2: {
        await this.getCoursesByStudent({
          userGuid: this.user.guid,
          availability: [CourseAvailability.Public, CourseAvailability.Private],
          status: [CourseStatus.Ended],
        });
        break;
      }
    }
  }
}
